.section1 {
  border-bottom: 1px solid #bfbfbf;
}
.section1 .title {
  width: 150px;
  height: 30px;
  line-height: 30px;
  background: #247cfb;
  color: #fff;
  font-size: 16px;
  cursor: default;
}
.section1 .time {
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  border-left: 2px solid #247cfb;
  padding-left: 5px;
  cursor: default;
  color: #343f51;
  font-weight: 700;
  margin: 7px 0 0 20px;
}
.section1 .back-btn {
  font-weight: 700;
  font-size: 14px;
}
.section2 {
  margin-top: 10px;
}
.section2 .sec2 {
  border: 1px solid #bfbfbf;
  padding: 10px;
  margin-bottom: 10px;
}
.section2 .sec2 .title {
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  border-left: 2px solid #247cfb;
  padding-left: 10px;
  font-weight: 700;
  color: #343f51;
}
.section2 .sec2-left .sec2-left-bottom {
  padding-top: 10px;
  padding-left: 5px;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-left {
  font-size: 12px;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-left li {
  padding: 8px 0;
  color: #343f51;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-left li span {
  color: #9e9e9e;
  padding-right: 8px;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-right {
  position: relative;
  height: 248px;
  width: 300px;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-right .gauge {
  width: 300px;
  height: 300px;
  position: absolute;
  right: -10px;
  top: -40px;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-right .gauge-tool {
  position: absolute;
  left: 50%;
  margin-left: -42px;
  bottom: 12%;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-right .gauge-tool .gauge-btn {
  border-radius: 30px;
  padding: 8px 10px;
}
.section2 .sec2-left .sec2-left-bottom .left-bot-right .gauge-tool .tool-text {
  width: 300px;
}
.section2 .sec2-right {
  height: 278px;
}
.section2 .sec2-right .sec2-right-bottom {
  padding-top: 10px;
}
.section2 .sec2-right .sec2-right-bottom li {
  font-size: 12px;
  background: #eee;
  color: #9e9e9e;
  padding: 6px 8px;
  border-radius: 8px;
  margin: 5px;
  cursor: default;
}
.section2 .sec2-right .sec2-right-bottom li .sec2-right-name {
  padding-bottom: 2px;
}
.section2 .sec2-right .sec2-right-bottom li .sec2-right-time {
  font-size: 10px;
}
.section2 .sec2-right .sec2-right-bottom .height46 .sec2-right-name {
  padding: 8px 0;
}
.section2 .sec2-right .sec2-right-bottom .tag1 {
  background: #ff4949;
  color: #fff;
}
.section2 .sec2-right .sec2-right-bottom .tag2 {
  background: #f7ba2a;
  color: #fff;
}
.section2 .sec2-right .sec2-right-bottom .tag3 {
  background: #50bfff;
  color: #fff;
}
.section2 .sec2-right .sec2-title-right {
  font-size: 12px;
  padding-right: 20px;
}
.section2 .sec2-right .sec2-title-right li:not(:last-child) {
  color: #9e9e9e;
  padding-right: 15px;
}
.section2 .sec2-right .sec2-title-right li:not(:last-child) .color-block {
  display: inline-block;
  width: 20px;
  height: 14px;
  margin-top: -2px;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 4px;
}
.section2 .sec2-right .sec2-title-right li:not(:last-child) .col1 {
  background: #ff4949;
}
.section2 .sec2-right .sec2-title-right li:not(:last-child) .col2 {
  background: #f7ba2a;
}
.section2 .sec2-right .sec2-title-right li:not(:last-child) .col3 {
  background: #50bfff;
}
.section2 .sec2-right .sec2-title-right li:not(:last-child) .col4 {
  background: #9e9e9e;
}
.section2 .sec2-right .sec2-title-right .tool-text {
  text-align: left;
}
.section2 .sec2-right .sec2-title-right .tool {
  width: 300px;
  text-align: right;
  display: block;
  position: absolute;
  right: 15px;
}
.section2 .sec2-right .sec2-title-right .tool .col5 {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  color: #9e9e9e;
  padding-left: 40px;
}
.section3 {
  border: 1px solid #bfbfbf;
  padding: 10px;
}
.section3 .title {
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  border-left: 2px solid #247cfb;
  padding-left: 10px;
  font-weight: 700;
  color: #343f51;
}
.section3 .bar-left {
  width: 400px;
  height: 200px;
}
.section3 .bar-right {
  width: 400px;
  height: 200px;
}
.section3 .sec3-right p {
  padding-left: 10%;
  width: 90%;
  color: #999;
  font-size: 12px;
}
.section3 .sec3-right p span {
  color: #343f51;
}
.section4 {
  border: 1px solid #bfbfbf;
  padding: 10px;
  margin-top: 10px;
  color: #556175;
  font-size: 12px;
}
.section4 .title {
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  border-left: 2px solid #247cfb;
  padding-left: 10px;
  font-weight: 700;
  color: #343f51;
}
.section4 .sec4-con {
  padding-left: 10px;
}
.section4 .sec4-con p {
  padding: 12px 0;
}
.section6 {
  border: 1px solid #bfbfbf;
  padding-top: 10px;
  margin-top: 10px;
  color: #556175;
  font-size: 12px;
  margin-bottom: 30px;
}
.section6 .title {
  margin: 0 10px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  border-left: 2px solid #247cfb;
  padding-left: 10px;
  font-weight: 700;
  color: #343f51;
}
.section6 .subhead {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #bfbfbf;
  border-top: 1px solid #bfbfbf;
  background: #f4f9fa;
  color: #343f51;
  padding: 10px 0 10px 22px;
  margin-top: 10px;
}
.section6 .subhead .sec6-subhead-title {
  display: inline-block;
  width: 120px;
}
.section6 .subhead .sec6-subhead-info {
  font-size: 12px;
}
.section6 .subhead .sec6-subhead-info a {
  color: #ff4949;
  padding-right: 4px;
}
.section6 .subhead .sec6-subhead-info .sec6-subhead-num {
  color: #50bfff;
  margin: 0 3px;
  font-weight: 700;
}
.section6 .sec6-con {
  margin: 0 20px;
}
.section6 .sec6-con .sec6-list:not(:last-child) {
  border-bottom: 1px solid #bfbfbf;
}
.section6 .sec6-con .sec6-list {
  padding: 10px 0;
}
.section6 .sec6-con .sec6-list .sec6-left {
  border-right: 1px solid #bfbfbf;
}
.section6 .sec6-con .sec6-list .sec6-left .sec6-img {
  margin: 0 20px;
}
.section6 .sec6-con .sec6-list .sec6-left .sec6-img .phoneb {
  display: block;
}
.section6 .sec6-con .sec6-list .sec6-left .sec6-left-info {
  line-height: 24px;
}
.section6 .sec6-con .sec6-list .sec6-left .sec6-left-info .sec6-left-title {
  color: #12addd;
  font-size: 15px;
}
.section6 .sec6-con .sec6-list .sec6-left .sec6-left-info .sec6-phone-info {
  color: #343f51;
}
.section6 .sec6-con .sec6-list .sec6-left .sec6-left-info .sec6-phone-info span {
  color: #9e9e9e;
}
.section6 .sec6-con .sec6-list .sec6-right {
  padding-left: 10px;
  line-height: 24px;
}
.section6 .sec6-con .sec6-list .sec6-right .sec6-right-ul li {
  color: #343f51;
}
.section6 .sec6-con .sec6-list .sec6-right .sec6-right-ul li span {
  color: #9e9e9e;
}
.section6 .sec6-con .sec6-list .sec6-right .sec6-right-ul li .dis-red {
  color: #343f51;
}
.section6 .sec6-con .sec6-list .sec6-right .sec6-right-ul li .theme-green {
  color: #343f51;
}
.section6 .sec6-con .sec6-list .sec6-right .sec6-right-ul li .orange {
  color: #fd9833;
}
