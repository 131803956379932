.look-card-con {
  flex-wrap: wrap;
  padding: 2% 8%;
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.look-card-con .look-card {
  width: 23%;
  margin: 10px;
}
