.head_v2{
  display: flex;
  align-items: center;
}

.head_v2_name{
  color: #FFF;
  margin-left: .5rem;
}

.header-ul {
  height: 50px;
  width: 100%;
  color: #fff;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

ol,
ul,
dl {
  margin-bottom: 0 ;
}

.page-logo{
  height: 1.5rem;
}

.split-1{
  width: .1rem;
  height: .8rem;
  margin: 0 .5rem;
  background-color: #FFFFFF;
  display: inline-block;
}

.down-icon{
  color: #fff;
}