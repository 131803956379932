.rick_block_head {
    background: green;
    height: 100%;
    flex-direction: column;
}

.rick_block_head_name {
    text-align: center;
    padding: 1.2rem;
    color: aliceblue;
}

.rick_block_head_icon {
    width: 100%;
    padding: 2rem;
}