.info-ul {
  font-size: 0.875rem;
  color: #666;
}
.info-ul .info-li {
  padding: 0.3125rem 0.625rem;
}
.info-ul .info-li .photo {
  padding-right: 20px;
}
.info-ul .info-li .photo p {
  padding-top: 0.3125rem;
}
.info-ul .info-li .photo img {
  display: block;
  height: 12.5rem;
}
.info-ul .info-li p {
  width: 100%;
}
.info-ul .info-li:nth-child(even) {
  background-color: #f2f2f2;
}
.tab10 {
  padding: 15px;
}
.tab10 .tab10-ul li {
  line-height: 30px;
  margin-bottom: 1px;
  border-bottom: 1px solid #eee;
}
.tab10 .tab10-ul li span {
  display: inline-block;
  width: 114px;
  background: #eee;
  padding-left: 15px;
  margin-right: 15px;
}
.tab10 .tab10-ul li i {
  font-style: normal;
  display: inline-block;
  line-height: 30px;
  width: 85px;
  text-align: center;
  padding-right: 15px;
  border-right: 1px solid #eee;
  margin-right: 15px;
}
.detail-title {
  font-size: 14px;
  padding: 10px;
  border-left: 4px solid #20a0ff;
  margin: 20px 0 5px 0;
  background: #f2f2f2;
}
.face-ul {
  border: 1px solid #dfe6ec;
  text-align: center;
}
.face-ul li {
  width: 25%;
}
.face-ul li :first-child {
  border: 1px solid #dfe6ec;
  background: #eef1f6;
}
.face-ul li p {
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  display: block;
  height: 50px;
  width: 100%;
  line-height: 50px;
}
.per-time {
  font-size: 14px;
  padding: 15px 0;
  color: #666;
}
.graph-detail li {
  padding-right: 20px;
}
