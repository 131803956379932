.tab-ul {
  position: relative;
  transition: transform 0.3s;
  float: left;
}
.tab-ul .tab-li {
  padding: 0 1rem;
  height: 42px;
  line-height: 42px;
  list-style: none;
  font-size: 14px;
  color: #8391a5;
  position: relative;
  border: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.tab-ul .tab-li.active {
  padding-right: 16px;
  padding-left: 16px;
}
.tab-ul .tab-li.active.closable {
  border: 1px solid #d1dbe5;
  border-bottom-color: #fff;
  border-radius: 4px 4px 0 0;
}
.tab-ul .tab-li .el-icon-close::before {
  transform: scale(0.7, 0.7);
  display: inline-block;
}
