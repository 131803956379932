.login-con {
  width: 100%;
  height: 100vh;
  background-image: url('../images/hou-tai-20190729/loginbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.login-con .row-bg {
  height: 100vh;
}
.login-con .row-bg .grid-content {
  height: 50vh;
  color: #fff;
}
.login-con .row-bg .grid-left {
  background-image: url('../images/hou-tai-20190729/login-form-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2%;
  font-size: 16px;
  border-radius: 6px 0 0 6px;
  height: 44vh;
}
.login-con .row-bg .grid-left .bottom-title {
  font-size: 14px;
  line-height: 14px;
}
.login-con .row-bg .grid-left .top-title .border-bottom4 {
  border-bottom: 4px solid #fff;
  padding-bottom: 6px;
}
.login-con .row-bg .grid-left .top-title .en-wel {
  font-size: 60px;
  padding-bottom: 8px;
}
.login-con .row-bg .grid-left .top-title .wel {
  padding: 10px 0;
}
.login-con .row-bg .grid-left .icon2 {
  width: 20px;
  height: 20px;
  padding: 0 10px;
}
.login-con .row-bg .grid-left .title {
  padding: 15px 0;
}
.login-con .row-bg .grid-right {
  background: white;
  -moz-box-shadow: -6px 6px 20px #333333;
  -webkit-box-shadow: -6px 6px 20px #333333;
  box-shadow: -6px 6px 20px #333333;
  border-radius: 6px;
}
.login-con .row-bg .grid-right .title-logo {
  display: block;
  margin: 0 auto;
  width: 60%;
  padding: 0 20% 10% 20%;
  position: relative;
}
.login-con .row-bg .grid-right .form-con {
  padding: 0 8%;
}
.login-con .row-bg .grid-right .form-con .el-input-group__prepend {
  background: #ddd;
}
.login-con .row-bg .grid-right .form-con .el-button {
  width: 100%;
}
.login-con .row-bg .grid-right .form-con .code-con {
  position: relative;
}
.login-con .row-bg .grid-right .form-con .code-con .code-item {
  position: absolute;
  width: 100px;
  right: 0;
  top: 0;
  cursor: pointer;
  box-sizing: border-box;
  border-left: 1px solid #bfcbd9;
  height: 36px;
  line-height: 1;
}
.login-con .row-bg .grid-right .form-con .lastitem {
  margin-top: 20px;
  margin-bottom: 0;
}
.login-con .row-bg .grid-right .form-con .lastitem .el-button.login-btn {
  border: none;
  margin-top: 10px;
  display: block;
  border-radius: 50px;
  background-image: url("../images/hou-tai-20190729/btn-bg.jpg");
  background-size: contain;
  background-repeat: repeat-y;
  text-align: center;
  margin: 0 auto;
  color: #fff;
}
