.section1{
  border-bottom: 1px solid #bfbfbf;
  .title{
    width:150px;
    height:30px;
    line-height:30px;
    background:#247cfb;
    color:#fff;
    font-size:16px;
    cursor:default;
  }
  .time{
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    border-left: 2px solid #247cfb;
    padding-left: 5px;
    cursor: default;
    color: #343f51;
    font-weight: 700;
    margin: 7px 0 0 20px;
  }
  .back-btn{
    font-weight: 700;
    font-size: 14px;
  }
}
.section2{
  margin-top:10px;
  .sec2{
    border: 1px solid #bfbfbf;
    padding:10px;
    margin-bottom:10px;
    .title{
      height: 20px;
      line-height: 20px;
      font-size: 15px;
      border-left: 2px solid #247cfb;
      padding-left: 10px;
      font-weight: 700;
      color: #343f51;
    }
  }
  .sec2-left{
    .sec2-left-bottom{
      padding-top:10px;
      padding-left:5px;
      .left-bot-left{
        font-size:12px;
        li{
          padding:8px 0;
          color:#343f51;
          span{
            color:#9e9e9e;
            padding-right:8px;
          }
        }
      }
      .left-bot-right{
        position: relative;
        height:248px;
        width:300px;
        .gauge{
          width:300px;
          height:300px;
          position: absolute;
          right:-10px;
          top:-40px;
        }
        .gauge-tool{
           position: absolute;
           left: 50%;
           margin-left: -42px;
           bottom: 12%;
           .gauge-btn {
              border-radius: 30px;
              padding: 8px 10px;
           }
           .tool-text{
             width:300px;
           }
        }
      }
    }
  }
  .sec2-right{
    height:278px;
    .sec2-right-bottom {
      padding-top:10px;
      li{
        font-size:12px;
        background: #eee;
        color: #9e9e9e;
        padding:6px 8px;
        border-radius:8px;
        margin:5px;
        cursor:default;
        .sec2-right-name{
          padding-bottom:2px;
        }
        .sec2-right-time{
          font-size:10px;
        }
      }
      .height46{
        .sec2-right-name {
          padding:8px 0;
        }
      }
      .tag1 {
        background:#ff4949;
        color:#fff;
      }
      .tag2 {
        background: #f7ba2a;
        color: #fff;
      }
      .tag3 {
        background: #50bfff;
        color: #fff;
      }
    }
    .sec2-title-right{
      font-size:12px;
      padding-right:20px;
      li:not(:last-child){
        color:#9e9e9e;
        padding-right:15px;
        .color-block {
          display: inline-block;
          width: 20px;
          height: 14px;
          margin-top: -2px;
          margin-right: 5px;
          vertical-align: middle;
          border-radius: 4px;
        }
        .col1{
          background:#ff4949;
        }
        .col2{
          background:#f7ba2a;
        }
        .col3{
          background:#50bfff;
        }
        .col4{
          background:rgb(158, 158, 158);
        }
      }
      .tool-text{
        text-align:left;
      }
      .tool {
        width: 300px;
        text-align: right;
        display:block;
        position: absolute;
        right:15px;
        .col5 {
          display: inline-block;
          font-size: 16px;
          line-height: 18px;
          color: #9e9e9e;
          padding-left:40px;
        }
      }
    }
  }
}
.section3{
  border: 1px solid #bfbfbf;
  padding:10px;
  .title {
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    border-left: 2px solid #247cfb;
    padding-left: 10px;
    font-weight: 700;
    color: #343f51;
  }
  .bar-left {
    width:400px;
    height: 200px;
  }
  .bar-right {
    width:400px;
    height: 200px;
  }
  .sec3-right{
    p{
      padding-left:10%;
      width:90%;
      color:#999;
      font-size:12px;
      span{
        color:#343f51;
      }
    }
  }
}
.section4{
  border: 1px solid #bfbfbf;
  padding:10px;
  margin-top:10px;
  color:#556175;
  font-size:12px;
  .title {
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    border-left: 2px solid #247cfb;
    padding-left: 10px;
    font-weight: 700;
    color: #343f51;
  }
  .sec4-con{
    padding-left:10px;
    p{
      padding:12px 0;
    }
  }
}
.section6{
  border: 1px solid #bfbfbf;
  padding-top:10px;
  margin-top:10px;
  color:#556175;
  font-size:12px;
  margin-bottom:30px;
  .title {
    margin:0 10px;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    border-left: 2px solid #247cfb;
    padding-left: 10px;
    font-weight: 700;
    color: #343f51;
  }
  .subhead{
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #bfbfbf;
    border-top: 1px solid #bfbfbf;
    background: #f4f9fa;
    color: #343f51;
    padding: 10px 0 10px 22px;
    margin-top:10px;
    .sec6-subhead-title {
      display:inline-block;
      width:120px;
    }
    .sec6-subhead-info{
      font-size: 12px;
      a{
        color:#ff4949;
        padding-right:4px;
      }
      .sec6-subhead-num{
        color: #50bfff;
        margin: 0 3px;
        font-weight: 700;
      }
    }
  }
  .sec6-con{
    margin: 0 20px;
    .sec6-list:not(:last-child) {
      border-bottom:1px solid #bfbfbf;
    }
    .sec6-list{
      padding:10px 0;
      .sec6-left{
        border-right: 1px solid #bfbfbf;
        .sec6-img{
          .phoneb{
            display: block;
          }
          margin:0 20px;
        }
        .sec6-left-info{
          line-height:24px;
          .sec6-left-title{
            color: #12addd;
            font-size: 15px;
          }
          .sec6-phone-info{
            span{
              color:#9e9e9e;
            }
            color:#343f51;
          }
        }
      }
      .sec6-right{
        padding-left: 10px;
        line-height:24px;
        .sec6-right-ul{
          li{
            color:#343f51;
            span{
              color:#9e9e9e;
            }
            .dis-red{
              color:#343f51;
            }
            .theme-green{
              color:#343f51;
            }
            .orange {
              color: #fd9833;
            }
          }
        }
      }
    }
  }
}
