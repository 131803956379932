.section {
  margin-bottom: 20px;
  width: calc(100% - 50px);
}
.section .title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.section .sec-bg {
  text-align: center;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 15px;
  -moz-box-shadow: 2px 2px 5px #d4d3d4;
  -webkit-box-shadow: 2px 2px 5px #d4d3d4;
  box-shadow: 2px 2px 5px #d4d3d4;
}
.section .sec-bg .icon {
  font-size: 54px;
  padding: 20px;
}
.section .sec-bg .money {
  font-size: 30px;
  font-weight: bold;
}
.section .sec-bg .info {
  font-weight: bold;
  padding: 0 15px;
  padding-top: 5px;
}
.section .sec-bg1 {
  background-image: url('../images/wel1.jpg');
}
.section .sec-bg2 {
  background-image: url('../images/wel2.jpg');
}
.section .sec-bg3 {
  background-image: url('../images/wel3.jpg');
}
.section .sec-bg4 {
  background-image: url('../images/wel4.jpg');
}
.section .wel-ul {
  width: 100%;
}
.section .wel-ul li:not(:last-child) {
  margin-right: 15px;
}
.section .wel-ul li {
  padding: 10px 20px;
  width: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  text-align: center;
}
.section .wel-ul li .sta-left {
  width: 66px;
  height: 66px;
}
.section .wel-ul li .sta-left .icon {
  display: block;
  line-height: 66px;
  color: #fff;
  font-size: 30px;
}
.section .wel-ul li .sta-right {
  border: 1px solid #e8ebf3;
  border-radius: 3px;
  border-left: 0;
}
.section .wel-ul li .sta-right p {
  white-space: nowrap;
  color: #999;
  font-size: 11px;
}
.section .wel-ul li .sta-right span {
  font-size: 20px;
}
.section .clearfix {
  color: #333;
}
.section .card-row .el-col {
  margin-bottom: 15px;
}
.section .card-row .el-col:nth-child(even) .card-body {
  height: 342px;
}
.section .card-row .el-col:nth-child(odd) .card-body {
  height: 342px;
}
.section .card-row2 .el-col {
  margin-bottom: 15px;
}
.section .card-row2 .el-col li {
  padding: 12px 0;
}
.section .card-body li {
  width: 50%;
  text-align: center;
}
.section .card-body li:nth-child(even) h1 {
  color: #f05844;
}
.section .card-body li:nth-child(odd) h1 {
  color: #69d972;
}
.section .card-body li p {
  color: #333;
}
